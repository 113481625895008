import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  format,
  formatDeliveryDate
} from 'lib/utils'

class ProductCardTallInfo extends PureComponent {
  static propTypes = {
    article: PropTypes.object.isRequired,
    lng: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    addQuantities: PropTypes.array.isRequired,
    quantity: PropTypes.number.isRequired,
    orderMonths: PropTypes.string.isRequired,
    orderMonthsSeason: PropTypes.string.isRequired,
    currentLanguage: PropTypes.string.isRequired
  }

  stockStatus = () => {
    let suffix = this.props.currentLanguage === 'en' ? 'pcs' : 'st'

    if (this.props.article.QuantityAvailable > 100) {
      return `100  ${suffix}+`
    } else if (this.props.article.QuantityAvailable > 0) {
      return `${this.props.article.QuantityAvailable} ${suffix} `
    } else if (this.props.article.QuantityAvailable + this.props.article.OrderedArticles > 0 && this.props.article.EstimatedDeliveryDate) {
      return formatDeliveryDate(this.props.article.EstimatedDeliveryDate)
    } else if (this.props.article.QuantityAvailable + this.props.article.OrderedArticles > 0) {
      return this.props.lng.Common.Ordered
    } else if (this.props.article.NoArticleStock) {
      return this.props.article.SeasonNews ? this.props.orderMonthsSeason : this.props.orderMonths
    } else {
      return this.props.lng.Common.SoldOut
    }
  }

  render() {
    const {
      ArticleText,
      ArticleNumber,
      Price,
      RegularPrice,
      QuantityPerPackage
    } = this.props.article

    const {
      ProductCard,
      Common
    } = this.props.lng

    return (
      <div>
        <div className="textContainer">
          <h4 className="productHeader">{ArticleText}</h4>
          <p className="productArtNo">{ProductCard.ArticleNumber}: {ArticleNumber}</p>
          <p className="productAvail">{ProductCard.StockStatus}: {this.stockStatus()}</p>
          <p className="quantityPackage">{ProductCard.PackageQuantity}: {QuantityPerPackage}</p>

          <div className="productPriceContainer">

            <p className={RegularPrice ? 'discountedPrice' : 'productPrice'}>
              {Common.Price}: <span className="priceValue">{format(Price)}/St</span>
            </p>

            {RegularPrice && (
              <p className="regularPrice">
                {format(RegularPrice)}/St
              </p>
            )}
          </div>

        </div>
        <div className="buttonContainer">
          <select name="quantity" onChange={this.props.onChange} value={this.props.quantity}>
            {
              this.props.addQuantities.map(quantity => <option key={quantity} value={quantity}>{quantity}</option>)
            }
          </select>
          <div className="addToBasket" onClick={this.props.addToCart}>{ProductCard.AddToCart}</div>
        </div>
      </div>
    )
  }
}

export default ProductCardTallInfo
